import ImgOne from '../../assets/process-1.png'
import ImgTwo from '../../assets/process-2.png'
import ImgThree from '../../assets/process-2.png'


const ProcessData = [
  {
    img: ImgOne,
    title: 'Cosmetic Dentistry',
    desc: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Fugiat, Non?'

  },
  {
    img: ImgTwo,
    title: 'Pediatric Dentistry',
    desc: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Fugiat, Non?'

  },
  {
    img: ImgThree,
    title: 'Dental Implants',
    desc: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Fugiat, Non?'

  },
]

export default ProcessData